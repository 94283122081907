var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showModal == true)?_c('Modal-Map',{attrs:{"type":_vm.infoModal},on:{"cancelModal":_vm.showModalOut}}):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.loadDimension,"rounded":"sm"}},[_c('div',{staticClass:"container_site",staticStyle:{"padding-bottom":"30px"}},[_c('div',{staticClass:"ubication_dimension",staticStyle:{"padding-bottom":"30px"}},[_c('p',{staticClass:"ubication_dimension--home",on:{"click":function($event){return _vm.getRoute('Home')}}},[_vm._v(" Inicio ")]),_c('img',{staticClass:"ubication_dimension--breadcrumb",attrs:{"src":require("@/assets/img/arrow-breadcrumb.svg")}}),_c('p',{staticClass:"ubication_dimension--name_dimension"},[_vm._v(" "+_vm._s(_vm.program.name)+" ")])]),_c('div',{staticClass:"container_dimension"},[_c('div',{staticClass:"container_dimension__content"},[_c('div',{staticClass:"container_dimension__content--card"},[_c('div',{staticClass:"container_dimension__content--imgDimension",style:({
                'background-image': ("url(" + (_vm.program.imagen.url) + ")"),
                'background-size': 'cover',
                'background-position': 'center',
                'max-width': '500px',
                'max-height': '80vh',
                'width': '30%'
              })}),_c('div',{staticClass:"container_dimension__content--description",style:({ 'background-color': _vm.getClient().primary_color })},[_c('div',{staticClass:"container_dimension__content--title"},[_vm._v(" "+_vm._s(_vm.program.name)+" ")]),_c('div',{staticClass:"container_dimension__content--text"},[_vm._v(" "+_vm._s(_vm.program.subtitle)+" ")])])]),_c('div',{staticClass:"container_dimension__content--segments"},[_c('div',{staticClass:"container_dimension__content--title_segment",style:({ 'background-color': _vm.getClient().primary_color })},[_vm._v(" "+_vm._s(_vm.program.label_text)+" ")]),_c('div',{staticClass:"container_dimension__content--description_segment",domProps:{"innerHTML":_vm._s(_vm.program.text)}})])])]),_c('div',{staticClass:"container_dimension_mobile mb-5",style:({ 'border-top': '10px solid ' + _vm.program.color })},[_c('div',{staticClass:"container_dimension_mobile--header_dimension",style:({
            'background-image': ("url(" + (_vm.program.imagen.url) + ")"),
            'background-size': 'cover',
            'background-position': 'center',
          })}),_c('div',{staticClass:"container_dimension_mobile--title_dimension"},[_vm._v(" "+_vm._s(_vm.program.name)+" ")]),_c('div',{staticClass:"container_dimension__mobil--text",style:({ padding: '15px' })},[_c('p',{style:({ 'text-align': 'left' })},[_vm._v(" "+_vm._s(_vm.program.subtitle)+" ")])]),_c('div',{staticClass:"container_dimension_mobile__card_segments"},[_c('div',{staticClass:"container_dimension_mobile__card_segment",style:({
              'border-top': '10px solid ' + _vm.program.color,
            })},[_c('div',{staticClass:"container_dimension_mobile__card_segment--title_segment"},[_vm._v(" "+_vm._s(_vm.program.label_text)+" ")]),_c('div',{staticClass:"container_dimension_mobile__card_segment--description_segment",domProps:{"innerHTML":_vm._s(_vm.program.text)}})])])]),_c('div',{staticClass:"container_btn_actions mb-5"},[_c('div',{staticClass:"container_btn_actions__btn_back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{attrs:{"src":require("@/assets/img/icono-regresar.svg"),"alt":""}}),_c('p',[_vm._v("Regresar")])]),_c('div',{staticClass:"container_btn_actions__btn_home",on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('img',{attrs:{"src":require("@/assets/img/icono-inicio.svg"),"alt":""}}),_c('p',[_vm._v("Inicio")])])])])]),(_vm.showProduct==true)?_c('Product-Modal',{attrs:{"product":_vm.product,"modalCategory":_vm.modalCategory},on:{"cancelProduct":_vm.showProductOut,"otherProduct":_vm.goToOtherProduct}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }