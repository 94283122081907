<template>
  <div>
    <Modal-Map
      v-if="showModal == true"
      @cancelModal="showModalOut"
      :type="infoModal"
    />
    <b-overlay :show="loadDimension" rounded="sm">
      <div class="container_site" style="padding-bottom: 30px;">
        <div class="ubication_dimension" style="padding-bottom: 30px;">
          <p class="ubication_dimension--home" @click="getRoute('Home')">
            Inicio
          </p>
          <img
            src="@/assets/img/arrow-breadcrumb.svg"
            class="ubication_dimension--breadcrumb"
          />
          <p class="ubication_dimension--name_dimension">
            {{ program.name }}
          </p>
        </div>
        <div class="container_dimension ">
     

          <div class="container_dimension__content">
            <div class="container_dimension__content--card">
              <div
                class="container_dimension__content--imgDimension"
                :style="{
                  'background-image': `url(${program.imagen.url})`,
                  'background-size': 'cover',
                  'background-position': 'center',
                  'max-width': '500px',
                  'max-height': '80vh',
                  'width': '30%'
                }"
              ></div>
              <div
                class="container_dimension__content--description"
                :style="{ 'background-color': getClient().primary_color }"
              >
                <div class="container_dimension__content--title">
                  {{ program.name }}
                </div>
                <div class="container_dimension__content--text">
                  {{ program.subtitle }}
                </div>
              </div>
            </div>
            <div
              class="container_dimension__content--segments"
            >
              <div
                class="container_dimension__content--title_segment"
                :style="{ 'background-color': getClient().primary_color }"
              >
                {{ program.label_text }}
              </div>
              <div
                class="container_dimension__content--description_segment"
                v-html="program.text"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="container_dimension_mobile mb-5"
          :style="{ 'border-top': '10px solid ' + program.color }"
        >
          <div
            class="container_dimension_mobile--header_dimension"
            :style="{
              'background-image': `url(${program.imagen.url})`,
              'background-size': 'cover',
              'background-position': 'center',
            }"
          ></div>
          <div class="container_dimension_mobile--title_dimension">
            {{ program.name }}
          </div>
          <div
            class="container_dimension__mobil--text"
            :style="{ padding: '15px' }"
          >
            <p :style="{ 'text-align': 'left' }">
              {{ program.subtitle }}
            </p>
          </div>
          <div class="container_dimension_mobile__card_segments">

            <div
              class="container_dimension_mobile__card_segment"
              :style="{
                'border-top': '10px solid ' + program.color,
              }"
             
            >
              <div
                class="container_dimension_mobile__card_segment--title_segment"
              >
                {{ program.label_text }}
              </div>
              <div
                class="
                  container_dimension_mobile__card_segment--description_segment
                "
                v-html="program.text"
              ></div>
             
            </div>
          </div>
        </div>
        <div class="container_btn_actions mb-5">
          <div class="container_btn_actions__btn_back" @click="$router.go(-1)">
            <img src="@/assets/img/icono-regresar.svg" alt="" />
            <p>Regresar</p>
          </div>
          <div
            class="container_btn_actions__btn_home"
            @click="$router.push({ name: 'Home' })"
          >
            <img src="@/assets/img/icono-inicio.svg" alt="" />
            <p>Inicio</p>
          </div>
        </div>
      </div>
    </b-overlay>
    <Product-Modal v-if="showProduct==true"
                      :product="product"
                      :modalCategory="modalCategory"
                      @cancelProduct="showProductOut"
                      @otherProduct="goToOtherProduct"
                    />
  </div>
</template>
<script>
import $ from "jquery";
import DimensionSpecial from "../shared/DimensionSpecial.vue";
import DimensionSpecialMobil from "../shared/DimensionSpecialMobil.vue";
var scroll_segments = [];
export default {
  components: { DimensionSpecial, DimensionSpecialMobil },
  data() {
    return {
      id: this.$route.params.id,
      program:[],
      loadDimension: true
    }
  },
  methods:{
    getProgram(){
            this.$http.get("programs/townhall/"+this.getClient().id)
            .then((response)=>{
                this.program = response.data
            })
            this.loadDimension = false;
        },
  },
  beforeMount(){
    console.log('entre aca')
    this.getProgram()
  }
}
</script>
<style scoped>
.full__screen {
  height: 20px;
}
.container_dimension__content--description_graph--img {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.container_dimension__content--content_products{
    margin-top: 43px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.container_dimension__content--content_products--item{
    height: auto;
    width: 260px;
    border-radius:5px;
    border: 1px solid #C6C6C6;
    margin:5px;
} 
.container_dimension__content--content_products--item__title p{
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin: 15px;
}
.container_dimension__content--content_products--item__button{
    height: 26px;
    width: 230px;
    border-radius: 5px;
    margin:0 15px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.container_dimension__content--content_products--item__button button{
    background: none;
    width: 100%;
    border: none;
    font-size: 12px;
    text-align: left;
    color: white;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container_dimension__content {
  width: 100% !important;
}

.container_dimension__content--description{
  width: 70% !important;
}
</style>